<template>
  <div>
    <el-container>
      <CommonTree ref="tree" :treeData="treeData" :defaultProps="defaultProps" :searchTitle="searchTitle"
                  :treeTitle="this.$t('cip.index.appIndex.theme.treeTitle')"
                  :isShowdig="true" :showCheckbox="false" @getNodeClick="treeNodeClick" node-key="id"
                  @getTreeAdd="treeAdd"
                  @getTreeEdit="treeEdit"
                  @getTreeDelete="treeNodeDel"


      />
      <el-main>
        <head-layout :head-btn-options="headBtnOptions" :head-title="$t(`cip.index.atomIndex.headTitle`)"
                     @head-remove="headRemove" @head-add-tabs="headAdd" @head-add-tabs1="headAdd1"
                     @head-dyn-sql="headDynSql"></head-layout>
          <grid-head-layout ref="gridHeadLayout" :grid-head-btn="gridHeadBtn" :search-columns="searchColumns"
                            @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout ref="gridLayOut" :grid-row-btn="gridRowBtn" :table-options="tableOptions" :table-data="tableData"
                     :table-loading="tableLoading" :data-total="dataTotal" @page-current-change="onLoad"
                     @page-size-change="onLoad"
                     @page-refresh-change="onLoad" @grid-row-detail-click="rowDetail" @row-remove="rowRemove"
                     @row-edit="rowEdit"
                     @row-query="rowDetail" @row-simulation="rowSimulation" @row-copy="rowCopy">

          <template #customBtn="{row}">
            <el-button
              @click="rowEdit(row)"
              size="small"
              type="text"
              v-if="row.cacheJobStatus != '1'"
            >{{ $t(`cip.cmn.btn.editBtn`) }}
            </el-button>
            <el-button
              @click="rowRemove(row)"
              size="small"
              type="text"
              v-if="row.cacheJobStatus != '1'"
            >{{ $t(`cip.cmn.btn.delBtn`) }}
            </el-button>
            <el-button
              @click="headStartTask(row)"
              size="small"
              type="text"
              v-if="row.isCache == '1'"
            >
              <span v-if="row.cacheJobStatus == '1'">{{ $t(`cip.cmn.btn.stopBtn`) }}</span>
              <span v-else>{{ $t(`cip.cmn.btn.startBtn`) }}</span>
            </el-button>
            <el-button
              @click="resettingIndexCacheData(row)"
              size="small"
              type="text"
              v-if="row.isCache == '1'"
            >
              {{ $t(`cip.cmn.btn.resetCacheBtn`) }}
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <CommonDialog v-if="showTreeDialog" :showFullscreen="true" width="60%" dialogTitle="指标分类" @cancel="showTreeDialog = false" @confirm="treeNodeSave">
      <avue-form :option="treeDialogOption" v-model="form" ref="formLayout"></avue-form>
    </CommonDialog>
    <dyn-table-dialog ref="dynTableDialog" :headForm.sync="dataForm" :type="type"></dyn-table-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  resettingIndexCacheData,
  changeCacheTaskStatus,
  extIndexPage as getList,
  remove,
  getTreeList2
} from "@/api/index/appIndexManage";
// import { getTreeList } from "@/api/dataCenter/programManage";

import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import ServerNameEnum from '@/util/ServerNameEnum';
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import dynTableDialog from "@/views/business/index/appIndex/common/dynTableDialog.vue";

import {
  tree,
  removeTree,
  removeTreeNew,
  saveOrUpdateTree,
  saveOrUpdateTreeNew,
  checkCategoryCodeExits,
  removeCategoryWithIndex
} from "@/api/index/appSystemCategory";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "index",
  data() {
    return {
      form: {
        parentId: '',
      },
      page: {
        currentPage: 1,
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE
      },
      treeReadOnlyFlag: false,
      extItemCodeFlag: true,
      dataTotal: null,
      tableData: [],
      drawerBox: false,
      drawerTitle: "",
      showItem: "",
      itemId: -1,
      bsItemGategory: {},
      treeLoading: false,
      tableLoading: false,
      crudLoading: false,
      treeData: [],
      crudData: [],
      selectionList: [],
      query: {
        themeId: "",
      },
      indexCatCode: '', // 点击左侧树的code
      isTheme: '',
      node: {},   //左侧树node
      fullscreen: false,
      showTreeDialog: false,

      searchTitle: 'name',
      defaultProps: {
        label: "name",
        value: "id",
        key: "id"
      },
      dataForm: {}

    };
  },
  props: {
    dataFormExt: {},
    shouIndexManage: {
      default: true
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: "新增基础指标",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add1',
        },
        {
          label: "新增复合指标",
          emit: "head-add-tabs1",
          type: "button",
          icon: "",
          btnOptType: 'add2',
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        },
        // {
        //   label: this.$t(`cip.index.atomIndex.dynSqlBtn`),
        //   emit: "head-dyn-sql",
        //   type: "button",
        //   icon: "",
        //   btnOptType: '',
        // }
      ]
    },
    tableOptions() {
      return {
        menu: this.shouIndexManage,
        linklabel: "indexCode",
        menuWidth: 170,
        column: [
          {
            label: this.$t('cip.index.atomIndex.indexCode'),
            prop: "indexCode",
            width: 180,
            search: true,
          },
          {
            label: this.$t('cip.index.atomIndex.indexName'),
            prop: "indexName",
            width: 220,
            search: true,
          },
          {
            label: this.$t('cip.index.atomIndex.confType'),
            prop: "confType",
            align: 'center',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_conf_type",
          },
          //指标单位
          {
            label: this.$t('cip.index.atomIndex.unitCode'),
            prop: "unitCode",
            align: 'center',
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_unit",
          },
          //时间维度
          {
            // label: this.$t('cip.index.atomIndex.indexType'),
            label: this.$t(`cip.index.omission.timeDimension`),
            prop: "timeDimension",
            align: 'center',
            value: 's',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          },
          //指标级别
          {
            // label: this.$t('cip.index.atomIndex.indexType'),
            label: this.$t(`cip.index.omission.indexLevel`),
            prop: "indexLevel",
            align: 'center',
            value: 'y',
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_level",
          },
          {
            label: this.$t(`cip.index.omission.isCache`),
            prop: "isCache",
            align: 'center',
            type: 'select',
            // dicData: [
            //   {
            //     label: '是',
            //     value: 1
            //   },
            //   {
            //     label: '否',
            //     value: 0
            //   },
            // ]
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: 'number',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
          }
        ]
      }
    },
    gridHeadBtn() {
      return []
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "indexCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.atomIndex.indexCode`)
        },
        {
          label: "",
          prop: "indexName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.index.atomIndex.indexName`),
        },

        // timeDimension
        {
          label: "",
          prop: "timeDimension",
          type: 'select',
          value: 'y',
          dataType: 'string',
          placeholder: this.$t(`cip.index.omission.timeDimension`),
          span: 4,
          // dicData: [{
          //   label: '年',
          //   value: 'Y'
          // }, {
          //   label: '月',
          //   value: 'M'
          // },
          // {
          //   label: '日',
          //   value: 'D'
          // },
          // ],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
        },

        // {
        //   label: "",
        //   prop: "isEffective",
        //   type: 'select',
        //   value: 'y',
        //   dataType: 'string',
        //   placeholder: this.$t(`cip.index.atomIndex.isEffective`),
        //   span: 4,
        //   dicData: [{
        //     label: '否',
        //     value: 'n'
        //   }, {
        //     label: '是',
        //     value: 'y'
        //   }],
        // }
      ]
    },
    gridRowBtn() {
      let dropBtn = [];
      dropBtn.push(
        {
          label: '指标数据',
          emit: "row-indexManager",
          type: "primary",
          icon: "",
        }, {
          label: '模拟数据',
          emit: "row-simulation",
          type: "primary",
          icon: "",
        });
      return [
        {
          label: this.$t(`cip.cmn.btn.viewBtn`),
          // label: '查看',
          emit: "row-query",
          type: "button",
          icon: ""
        },
        {
          label: this.$t(`cip.cmn.btn.copyBtn`),
          // label: '复制',
          emit: "row-copy",
          type: "button",
          icon: ""
        },
      ]
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.index.app.category.parentCode"),
            prop: "parentId",
            type: "tree",
            props: {
              label: 'categoryName',
              value: 'id',
              key: 'parentId',

            },
            placeholder: this.$t(`cip.index.app.category.parentCode`),
            dicUrl: `${ServerNameEnum.SERVER_INDEX_}/indexDefine/tree`,


          },
          {
            label: this.$t(`cip.index.app.category.categoryCode`),
            prop: "categoryCode",
            maxlength: 20,
            readonly: this.treeReadOnlyFlag,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.categoryCode`),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t(`cip.index.app.category.categoryName`),
            prop: "categoryName",
            maxlength: 20,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.categoryName`),
                trigger: "blur",
              },
            ],
          },
          {
            label: this.$t(`cip.index.app.category.sort`),
            prop: "sort",
            type: 'number',
            controls: false,
            precision: 0,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.index.app.category.sort`),
                trigger: "blur",
              },
            ],
          },

          // {
          //   label: '自动同步',
          //   prop: 'isAuto',
          //   type: 'radio',
          //   labelWidth: 130,
          //   props: {
          //     label: "label",
          //     value: "value"
          //   },
          //   dataType: 'number',
          //   dicData: [
          //     {
          //       label: '是',
          //       value: 1,
          //     },
          //     {
          //       label: '否',
          //       value: 0
          //     },
          //
          //   ],
          //   value: '0',
          // },
        ]
      }
    },

  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    dynTableDialog,
    CommonDialog
  },
  created() {
    let p1 = sessionStorage.getItem("zbparms")
    p1 = JSON.parse(p1);
    let p2 = JSON.parse(JSON.stringify(p1))


    if (p1) {
      if (p1.query.themeId) {
        this.themeId = p1.query.themeId
        this.query.themeId = p1.query.themeId
      }
      this.$nextTick(() => {
        this.$refs.gridHeadLayout.searchForm = p2.query
        this.onLoad({pageSize: p2.size, currentPage: p2.current}, p2.query)
      })


    } else {
      // this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});
    }

    getTreeList2()
      .then((result) => {
        let p1 = sessionStorage.getItem("zbparms")
        p1 = JSON.parse(p1);
        this.treeData = result.data.data;

        this.treeLoading = false;

        if (!p1 || !p1.query.themeId) {

          this.$nextTick(() => {

            let p1 = this.treeData[0].children[0]

            this.$refs.tree.$refs.commonTree.setCurrentKey(p1.id)
            this.node = p1;
            const {indexCatCode, isTheme, id} = p1;
            this.themeId = id;
            this.isTheme = isTheme;

            this.onLoad({
              pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
              currentPage: 1
            }, this.$refs.gridHeadLayout.searchForm);
          })
        }
        if (p1.query.themeId) {

          this.$nextTick(() => {
            this.$refs.tree.$refs.commonTree.setCurrentKey(p1.query.themeId)
          })
        }


      })
      .catch((err) => {
        this.treeLoading = true;
      });
  },

  methods: {

    fullClick() {
      this.fullscreen = !this.fullscreen;
    },

    initTree() {
      // tree({ parentId: 0 }).then(res => {
      //   const { data } = res.data;
      //   this.treeData = data;
      // });
      getTreeList2()
        .then((result) => {
          let p1 = sessionStorage.getItem("zbparms")
          p1 = JSON.parse(p1);
          this.treeData = result.data.data;

          this.treeLoading = false;

          if (!p1 || !p1.query.themeId) {

            this.$nextTick(() => {

              let p1 = this.treeData[0].children[0]

              this.$refs.tree.$refs.commonTree.setCurrentKey(p1.id)
              this.node = p1;
              const {indexCatCode, isTheme, id} = p1;
              this.themeId = id;
              this.isTheme = isTheme;

              this.onLoad({
                pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
                currentPage: 1
              }, this.$refs.gridHeadLayout.searchForm);
            })
          }
          if (p1.query.themeId) {

            this.$nextTick(() => {
              this.$refs.tree.$refs.commonTree.setCurrentKey(p1.query.themeId)
            })
          }


        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    // 新增左侧树节点
    treeNodeSave() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          if (this.node.id == this.form.parentId) {
            return this.$message.error('节点不能以自己为父节点！！')
          }

          let aa = JSON.parse(JSON.stringify(this.treeData));
          aa = this.flatten(aa)
          aa.map(element => {
            if (element.id == this.form.parentId && element.isAuto == 1) {
              return this.$message.error('该节点自动同步，不能为父节点！！')
            }
          });

          if (this.form.id) {
            this.saveCategory();

          } else {
            checkCategoryCodeExits(this.form).then(res => {
              if (res.data.data) {
                this.$message.warning(this.$t('cip.index.app.category.errorMsg1'));
                return;
              }
              this.saveCategory();
            })
          }
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = [];
          result = result.concat(arr[i]);
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },
    saveCategory() {
      saveOrUpdateTreeNew(this.form).then(res => {
        const {msg, data} = res.data;
        if (data.code === 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        } else {
          this.$message.success(msg);
        }
        this.initTree(true)
        this.showTreeDialog = false;
        // this.$refs.formLayout.$refs.form.allDisabled = false
        // this.$refs.CommonTree.$refs.commonTree.setCurrentKey(this.sysCategoryId)
      })
    },
    // 左侧树编辑
    treeEdit(data) {
      console.log(data, '编辑data');
      let aa = JSON.parse(JSON.stringify(this.treeData));
      aa = this.flatten(aa)
      aa.map((item) => {
        if (item.id == data.id) {
          console.log(item, data);
          data = item
        }
      })

      if (data.isAuto == 1) {
        this.$message({
          message: '此指标体系为自动同步，不允许手工编辑，删除！',
          type: 'warning'
        });
      } else {
        this.treeReadOnlyFlag = true;
        this.fullscreen = false;
        this.showTreeDialog = true;
        this.form = data;
      }
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }
      // if (this.$refs.queryIndexManageWithCategory.tableData.length > 0) {
      //   this.$message.warning(this.$t('cip.cmn.msg.warning.hasIndicators'))
      //   return
      // }
      //
      //
      // if (data.isAuto == 1) {
      //   this.$message({
      //     message: '此指标体系为自动同步，不允许手工编辑，删除！',
      //     type: 'warning'
      //   });
      //   return
      // }

      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTreeNew(data.id).then(() => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          this.$refs.queryIndexManageWithCategory.onLoad({
            pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
            currentPage: 1
          }, {});
          done()
        })
      }).catch(() => {
      });
    },
    treeNodeClick(node) {
      this.node = node;
      const {indexCatCode, isTheme, id} = node;
      this.themeId = id;
      this.isTheme = isTheme;

      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, this.$refs.gridHeadLayout.searchForm);
    },
    treeAdd() {
      this.form = {};
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    getSelectionList() {
      return this.$refs.gridLayOut.selectionList
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('sinoma.tip.selectEmpty'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (ids.length === 0) {
            //只选中了空数据
            let index = 0;
            selectionList.filter(e => !e.id).forEach(e => {
              this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
              index++
            })
            this.$refs.gridLayOut.selectionClear()
            return;
          }
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});

              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.selectionClear()
            });
        }).catch(() => {
      })
    },
    rowRemove(row) {
      let that = this;
      console.log(this.page, "eeeeeeeeeeeeeeee");
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.id).then(() => {
            // this.$message({
            //   type: "success",
            //   message: this.$t('cip.cmn.msg.success.operateSuccess')
            // });

            this.onLoad(this.page);
          }).catch(res => {
          })
        })
      // .then(() => {
      //   this.onLoad({ pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1 }, {});
      //   this.$message({
      //     type: "success",
      //     message: this.$t('cip.cmn.msg.success.operateSuccess')
      //   });
      // });
    },
    rowDetail(row) {
      this.$router.push({
        path: `/index/appIndex/view`,
        query: {
          id: row.id,
          themeId: row.themeId,
          pageType: 'view'
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/index/appIndex/edit`,
        query: {
          id: row.id,
          themeId: row.themeId,
          pageType: 'edit'
        }
      })
    },
    rowCopy(row) {
      this.$router.push({
        path: `/index/appIndex/edit`,
        query: {
          id: row.id,
          themeId: row.themeId,
          pageType: 'copy'
        }
      })
    },
    rowSimulation(row) {
      this.$router.push({
        path: `/index/appIndex/simulation`,
        query: {
          id: row.id,
          themeId: row.themeId,
          pageType: 'edit'
        }
      })
    },
    rowQuery(row) {
      this.dataForm = row
      console.log('dataForm:', this.dataForm)
      this.$refs.dynTableDialog.openDialog(this.dataForm);
    },
    // 新增
    headAdd() {
      if (Object.keys(this.node).length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning') + "[" + this.$t('cip.index.appIndex.theme.treeTitle') + "]");
        return;
      }
      console.log(this.node, 'this.node')
      this.$router.push({
        path: `/index/appIndex/add`,
        query: {
          pageType: 'add',
          node: this.node,
          indexType: '基础指标'
        }
      })
    },

    // 新增
    headAdd1() {
      if (Object.keys(this.node).length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning') + "[" + this.$t('cip.index.appIndex.theme.treeTitle') + "]");
        return;
      }
      console.log(this.node, 'this.node')
      this.$router.push({
        path: `/index/appIndex/add`,
        query: {
          pageType: 'add',
          node: this.node,
          indexType: '复合指标'
        }
      })
    },
    headDynSql() {
      if (Object.keys(this.node).length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning') + "[" + this.$t('cip.index.appIndex.theme.treeTitle') + "]");
        return;
      }
      this.$router.push({
        path: `/md/index/index/addSql`,
        query: {
          pageType: 'add',
          node: this.node
        }
      })
    },
    gridHeadSearch(query) {
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, query)
    },
    gridHeadEmpty(query) {
      this.themeId = null
      this.query.themeId = null
      this.$refs.tree.$refs.commonTree.setCurrentKey(null)
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, query)
    },

    close() {
      this.drawerBox = false;
      this.page.currentPage = 1;
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});
    },
    rowSelection(row) {
      this.$refs.crud.toggleRowSelection(row);
    },
    saveHandler() {
      this.drawerBox = true;
      this.drawerTitle = "添加物料";
      this.showItem = "add";
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.crudLoading = true;
      this.query.themeId = this.themeId;
      Object.assign(this.query, this.dataFormExt)
      let p1 = {
        query: Object.assign(params, this.query)
      }
      p1.current = page.currentPage
      p1.size = page.pageSize
      let p2 = JSON.stringify(p1)
      // this.$nextTick(()=>{
      //   this.$refs.gridHeadLayout.searchForm = p1.query
      // })

      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.dataTotal = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
          sessionStorage.setItem("zbparms", p2)
          this.$nextTick(() => {
            this.$refs.gridHeadLayout.searchForm = p1.query
          })

        })
        .catch(() => {
          this.crudLoading = true;
        });
    },
    searchChange() {
      const extItemCatId = this.query.extItemCatId;
      this.query = {
        keyword: this.keyword,
      };
      this.query.extItemCatId = extItemCatId;
      this.page.currentPage = 1;
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});
    },
    searchReset() {
      const extItemCatId = this.query.extItemCatId;
      this.query = {};
      this.query.extItemCatId = extItemCatId;
      this.page.currentPage = 1;
      this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    resettingIndexCacheData(row) {
      this.$confirm(`是否重置该指标缓存?`, "提示", {
        type: "warning",
      }).then(() => {
        resettingIndexCacheData(row.id)
          .then(res => {
            this.$message.success("指标缓存重置成功！");
            this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});
          })
      })
    },
    headStartTask(row) {
      let cacheJobStatus = row.cacheJobStatus
      let status;
      let text = "";
      if (cacheJobStatus == "1") {
        // 停止
        status = 0;
        text = "停止"
      } else {
        // 启动
        status = 1;
        text = "启动"
      }

      this.$confirm(`是否${text}该指标缓存任务?`, "提示", {
        type: "warning",
      }).then(() => {
        changeCacheTaskStatus(row.id, status)
          .then(res => {
            this.$message.success(`${text}指标缓存任务成功！`);
            this.onLoad({pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE, currentPage: 1}, {});
          })
      })
    }
  },
};
</script>

