<template>
  <div>
    <el-container>
      <CommonTree
          :treeData="treeData"
          :treeTitle="$t(`cip.plat.message.list.title.msgTypeTitle`)"
          :defaultProps="defaultProps"
          :searchTitle="searchTitle"
          :isShowdig="false"
          :showCheckbox="false"
          @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t(`cip.plat.message.list.title.indexHeadTitle`)"
            @head-readAll="readAll"
            @head-message-setting="headMessageSetting"
            @head-read="headRead"
            @head-remove="headRemove"
        ></head-layout>
        <div class="headers">
          <grid-head-layout
              ref="gridHeadLayout"
              :grid-head-btn="gridHeadBtn"
              :search-columns="searchColumns"
              @grid-head-search="gridHeadSearch"
              @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        </div>
        <grid-layout
            ref="gridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
            @row-view="rowDetail"
            @row-remove="rowDel"
            @gird-handle-select-click="selectionChange"
        >
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
        :title="msg.msgTile"
        :visible.sync="dialogVisible"
        :show-close=false
        width="30%">
       <p> {{$t('cip.plat.message.list.field.createTime')}}：{{msg.createTime}}</p>
      <p>{{$t('cip.plat.message.list.field.msgContent')}}：<span v-html="msg.msgContent"></span></p>
      <div v-if="attachment.length > 0">
        <span>{{$t('cip.plat.message.list.field.attachment')}}：</span>
        <span style="color: #1890ff;cursor: pointer;text-decoration: underline;margin-right:10px" v-for="item in attachment" :key="item.id" @click="downloadFile(item)">{{item.originalName}}</span>
      </div>
      <div v-if="images.length > 0">
        <p>{{$t('cip.plat.message.list.field.picture')}}：</p>
        <div style="display:flex;">
          <img v-for="item in images" :key="item.id" style="width:50px;height:50px;margin-right:10px;" :src="item.link" alt="">
        </div>
      </div>
      <div v-if="videos.length > 0">
        <p>{{$t('cip.plat.message.list.field.video')}}：</p>
        <span style="color: #1890ff;cursor: pointer;text-decoration: underline;margin-right:5px" v-for="item in videos" :key="item.id"  @click="openVideo(item)">{{item.link}}</span>
      </div>
      <div v-if="pcUrl" style="margin-top:10px">
         <el-button type="primary" @click="goToUrl">{{$t('cip.plat.message.list.field.buttonName')}}</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeDialog">{{ $t("cip.cmn.btn.defBtn") }}</el-button>
    </span>
    </el-dialog>
    <el-dialog
        :title="$t(`cip.plat.message.list.title.msgSettingTitle`)"
        :visible.sync="settingVisible"
        append-to-body
        width="400px"
    >
    <el-tabs type="border-card">
      <el-tab-pane :label="$t('cip.plat.message.list.title.notifySettingTitle')">
        <el-tree :data="treeData"
                show-checkbox node-key="id"
                ref="msgSetting"
                :default-checked-keys="userMsgSettingObj"
                :props="props"
                default-expand-all
                check-strictly>
        </el-tree>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.plat.message.list.title.bcstSettingTitle')">
        <el-tree :data="treeData"
                show-checkbox node-key="id"
                ref="bcstSetting"
                :default-checked-keys="userBcstSettingObj"
                :props="props"
                default-expand-all
                check-strictly>
        </el-tree>
      </el-tab-pane>
    </el-tabs>
      <span slot="footer" class="dialog-footer">
          <el-button @click="settingVisible = false">{{
              $t("cip.cmn.btn.celBtn")
            }}</el-button>
          <el-button type="primary" @click="handleSubmit" :loading="btnLoading">{{
              $t("cip.cmn.btn.defBtn")
            }}</el-button>
        </span>
    </el-dialog>
     <el-dialog
        :visible.sync="videoVisible"
        append-to-body
        width="30%"
        height="500px"
        @close="closeVideo"
    >
     <video style="width:100%;height:100%" controls controlsList="nodownload" :src="openVideoUrl"></video>
    </el-dialog>
  </div>
</template>

<script>

import {tree} from "@/api/message/msgtemplateitem";

let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {selectMsgByAccount, readByMsgId, readList, readAll, remove, getMsgDetail, getSelectParams} from "@/api/message/sendInfo";
import CommonTree from "@/views/components/com_tree";
import {getUserSetting, submitUserSetting, getBcstSetting, submitBcstSetting} from "@/api/user";
import {downloadFileBlob} from "@/util/util"
import {DICT_BIZ} from "@/util/dictConstant";
export default {
  name: "channel",
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      btnLoading: false,
      props: {
        label: "msgTypeName",
        value: "id"
      },
      treeData: [],
      userMsgSettingObj: [],
      userBcstSettingObj: [],
      searchTitle: 'msgTypeName',
      defaultProps: {
        label: "msgTypeName",
        value: "key",
        key: "msgTypeCode"
      },
      msgTypeCode: '', // 点击左侧树的code
      msg: {},
      tableLoading: true,
      dialogVisible: false,
      selectionList: [],
      settingVisible: false,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      videoVisible: false,
      openVideoUrl: '',
      pcUrl: '',
      attachment: [], //附件
      images: [],  //图片
      videos: []  //视频
    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
    searchColumns() {
      return [
        {
          prop: "msgTile",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.plat.message.list.field.msgTile'),
        },
        {
          prop: "msgContent",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.plat.message.list.field.msgContent'),
        },
        {
          prop: "isRead",
          span: 4,
          type: 'select',
          dicUrl: `${DICT}yes_no`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t('cip.plat.message.list.field.isRead'),
        },
        {
          prop: "startDate",
          type: 'date-format',
          span: 4,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          placeholder: this.$t(`cip.plat.message.sendRecord.field.startDate`)
        },
        {
          prop: "endDate",
          type: 'date-format',
          span: 4,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          placeholder: this.$t(`cip.plat.message.sendRecord.field.endDate`),
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        column: [
          {
            label: this.$t('cip.plat.message.list.field.msgTile'),
            prop: 'msgTile',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.message.list.field.msgContent'),
            prop: 'msgContent',
            // overHidden: true,
          },
          {
            label: this.$t('cip.plat.message.list.field.isRead'),
            prop: 'isRead',
            dicUrl: `${baseUrl}yes_no`,
            dataType: "string",
            align: "center",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          {
            label: this.$t('cip.plat.message.list.field.createTime'),
            prop: 'createTime',
            align: "center",
            overHidden: true
          }
        ],
      }
    },
    gridRowBtn() {
      return [
        {
          label: this.$t('cip.cmn.btn.viewBtn'),
          emit: "row-view",
          type: "button",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        },
      ]
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.plat.message.list.btn.readAllBtn'),
          emit: "head-readAll",
          type: "button",
          icon: "",
        },
        {
          label: this.$t('cip.plat.message.list.btn.readBtn'),
          emit: "head-read",
          type: "button",
          icon: "",
        }, {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
        }, {
          label: this.$t('cip.plat.message.list.btn.messageSettingBtn'),
          emit: "head-message-setting",
          type: "button",
          icon: "",
        },
      ]
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        console.log("进行输出----7-------",ele.id)
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.onLoad(this.page, {});
    this.getTypeTree();
    this.getUserSetting();
    this.getBcstSetting();
  },
  methods: {
    closeVideo(){
      this.openVideoUrl = '';
      this.videoVisible = false;
    },
    closeDialog(){
       this.openVideoUrl = '';
       this.pcUrl = '';
       this.attachment = []; //附件
       this.images = [];  //图片
       this.videos = [];
       this.dialogVisible = false
    },
    goToUrl(){
     window.open(this.pcUrl,'_blank')
    },
    downloadFile(item){
      downloadFileBlob(item.link, item.originalName)
    },
    openVideo(item){
      this.videoVisible = true;
      this.openVideoUrl = item.link
    },
    handleSubmit() {
      this.btnLoading = true
      let msgList = ""
      let bcstList = ""
      if (this.$refs.msgSetting.getCheckedKeys().length > 0) {
        msgList = this.$refs.msgSetting.getCheckedKeys().join(",");
      }
      if (this.$refs.bcstSetting.getCheckedKeys().length > 0) {
        bcstList = this.$refs.bcstSetting.getCheckedKeys().join(",");
      }
      submitUserSetting(msgList).then((res) => {
        this.userMsgSettingObj = res.data.data
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        this.settingVisible = false
        this.btnLoading = false
      })
      submitBcstSetting(bcstList).then((res) => {
        this.userBcstSettingObj = res.data.data
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        this.settingVisible = false
        this.btnLoading = false
      })
    },
    getUserSetting() {
      getUserSetting(this.userInfo.user_id).then((res) => {
        this.userMsgSettingObj = res.data.data
      })
    },
    getBcstSetting() {
      getBcstSetting(this.userInfo.user_id).then((res) => {
        this.userBcstSettingObj = res.data.data
      })
    },
    getTypeTree() {
      tree().then((result) => {
        this.treeData = result.data.data;
        this.treeLoading = false;
      })
          .catch(() => {
            this.treeLoading = true;
          });
    },
    readAll(){
      this.$confirm(this.$t('cip.plat.message.list.msg.readAll'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "success"
      })
        .then(() => {
          return readAll();
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    headMessageSetting() {
      this.settingVisible = true
    },
    headRead() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.plat.message.list.msg.read'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "success"
      })
        .then(() => {
          return readList(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      let count = 0;
      this.selectionList.forEach(e => {
        if (e.isRead === 0) {
          count++;
        }
      })
      if (count > 0) {
        this.$confirm(this.$t('cip.plat.message.list.msg.removeContainNotRead'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        }).then(() => {
          remove(this.ids).then((res) => {
            if (res.data == true) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            } else {
              this.onLoad(this.page);
              this.$message({
                type: "error",
                message: this.$t("cip.cmn.msg.success.delSuccess")
              });
            }
          });
        })
      } else {
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        }).then(() => {
          remove(this.ids).then((res) => {
            if (res.data == true) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            } else {
              this.onLoad(this.page);
              this.$message({
                type: "error",
                message: this.$t("cip.cmn.msg.success.delSuccess")
              });
            }
          });
        })
      }
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.page.total = 0;
      let tree =[]
      console.log("进行输出-----node.msgTypeCode--",node.msgTypeCode)
      this.getChildNode(this.treeData,node.msgTypeCode,tree)
      this.msgTypeCode = tree
      this.onLoad(this.page, {});
    },
    /**
     * @Description:获取指定节点下下的所有子节点

     * @Author: ShiWei

     * @Date: 2020-11-04
     */
    getChildNode(nodes, item, arr) {
      for (let el of nodes) {
        if (el.msgTypeCode === item) {
          arr.push(el.msgTypeCode);
          if (el.children) {
            this.childsNodeDeepWay(el.children, arr);
          }
        } else if (el.children) {
          this.getChildNode(el.children, item, arr);
        }
      }
      return arr;
    },
    childsNodeDeepWay(nodes, arr) {
      if (nodes)
        nodes.forEach((ele) => {
          arr.push(ele.msgTypeCode);
          if (ele.children) {
            this.childsNodeDeepWay(ele.children, arr);
          }
        });
    },
    //特殊符号转义
    convHtmlStr(str) {
      var res =
        str
          .replace(/&nbsp;/g," ")
          .replace(/&apos;/g,"'")
          .replace(/&quot;/g,"\"") //双引号转义一下
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&")
          .replace(/&copy;/g,"©");
      return res;
    },
    rowDetail(row) {
      this.dialogVisible = true;
      this.msg = row;
      if (row.msgId == -1) {
        row.msgId = row.id
      }
      getMsgDetail(row.msgId).then((res) => {
        if (res.data.code === 200) {
          res.data.data.forEach((item) => {
            if (item.extension == 'txt' || item.extension == 'doc' || item.extension == 'pdf' || item.extension == 'docx') {
              this.attachment.push(item);
            } else if (item.extension == 'jpg' || item.extension == 'png' || item.extension == 'gif') {
              this.images.push(item);
            } else if (item.extension == 'mp4') {
              this.videos.push(item);
            }
          })
        }

      })
      getSelectParams(row.msgId).then((result) => {
        //进行解析赋值
        this.msg.msgContent = this.convHtmlStr(result.data.data.msgContent)
        if(result.data.code === 200 && result.data.data){
          if(result.data.data.params) {
            let params = JSON.parse(result.data.data.params).replaceAll(',','&')
            this.pcUrl = result.data.data.pcUrl + '?' + params
          } else {
            this.pcUrl = result.data.data.pcUrl
          }
        }
      })
      readByMsgId(row.id).then(() => {
        this.onLoad(this.page, {})
      })
    },
    rowDel(row) {
      if (row.isRead === 0) {
        this.$confirm(this.$t('cip.plat.message.list.msg.removeNotRead'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        }).then(() => {
          remove(row.id).then((res) => {
            if (res.data == true) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            } else {
              this.onLoad(this.page);
              this.$message({
                type: "error",
                message: this.$t("cip.cmn.msg.success.delSuccess")
              });
            }
          });
        })
      } else {
        this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        }).then(() => {
          remove(row.id).then((res) => {
            if (res.data == true) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            } else {
              this.onLoad(this.page);
              this.$message({
                type: "error",
                message: this.$t("cip.cmn.msg.success.delSuccess")
              });
            }
          });
        })
      }
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.msgTypeCode = '',
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.msgTypeCode) {
        params.templateCode = this.msgTypeCode;
      }
      console.log("进行输出----123-----",this.$refs.gridHeadLayout.searchForm)
      console.log("进行输出----params.templateCode-----",params.templateCode)
      selectMsgByAccount(
        this.page.currentPage,
        this.page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.selectionClear();
      });
    }
  }
};
</script>
<style>
</style>
