<template>
  <div style="position: relative" class="flex-container">
    <!-- <CommonTree
        ref="commonTree"
        treeTitle="项目架构"
        :treeData="treeData"
        :defaultProps="Props"
        :showCheckbox="false"
        @getNodeClick="handleNode"
        node-key="id"
      >
      </CommonTree> -->
    <CommonTreeNew
      ref="commonTree"
      treeTitle="项目架构"
      :defaultProps="Props"
      :showCheckbox="false"
      @getNodeClick="handleNode"
      :urlParmas="{
        tenantId: '',
        deptCategory: '5,2',
        parentId: userInfo.dept_id,
      }"
      node-key="id"
      @getTreeData="commonTreeData"
    ></CommonTreeNew>
    <div class="flex-one" style="background-color: #fff">
      <head-layout
        v-if="isIndex"
        head-title="应急预案"
        :head-btn-options="headBtnOptions"
        @head-add="handleAdd"
        @head-delete="handleDelete"
      ></head-layout>
      <!-- 头部筛选条件 -->
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
      <grid-layout
        ref="gridLayOut"
        :table-options="tableOption"
        :table-data="tableData"
        :table-loading="tableLoading"
        @grid-row-detail-click="rowView"
        @gird-handle-select-click="selectionChange"
        :data-total="page.total"
        :page="page"
        @page-current-change="onLoad"
        @page-size-change="onLoad"
      >
        <template #customBtn="{ row }">
          <div v-if="isIndex">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              v-if="row.erStatus && row.erStatus != 'PREPARE'"
              @click="rowView(row)"
              >查看
            </el-button>
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              v-if="
                row.erStatus &&
                row.erStatus == 'PREPARE' &&
                row.createUser == userInfo.user_id
              "
              @click="rowEdit(row)"
              >编辑
            </el-button>
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              v-if="
                row.erStatus &&
                row.erStatus == 'PREPARE' &&
                row.createUser == userInfo.user_id
              "
              @click="rowDel(row)"
              >删除
            </el-button>
          </div>
          <el-button
            v-if="!isIndex"
            style="margin: 0 3px"
            type="text"
            size="small"
            @click="selectRow(row)"
            >选择</el-button
          >
        </template>
      </grid-layout>
    </div>
  </div>
</template>

  <script>
import { erplanPage, erplanRemove } from "@/api/contingency/emergencyPlan";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { exportBlob } from "@/api/common";
import { dateNow } from "@/util/date";
import { getToken } from "@/util/auth";
import { downloadXls } from "@/util/util";
import CommonTree from "@/views/components/com_tree/index.vue";
import { getDeptTree } from "@/api/system/dept";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  props: {
    isIndex: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      treeNode: {},
      treeData: [],
      Props: {
        children: "children",
        label: "title",
      },
      form: {},
      query: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: this.isIndex, // isIndex == true 表示是当前项目列表页面 == false 表示从黑名单组织或者黑名单人员中点击弹框进入
        selectable: (row, index) => {
          return (
            row.createUser == this.userInfo.user_id &&
            row.erStatus != "FINISHED"
          );
        },
        linklabel: this.isIndex ? "planCode" : "",
        menuWidth: this.isIndex ? "130" : "80",
        menuFixed: "right",
        column: [
          {
            label: "预案编号",
            prop: "planCode",
            minWidth: 130,
            overHidden: true,
            align: "center",
          },
          {
            label: "预案名称",
            prop: "planName",
            minWidth: 130,
            align: "left",
            overHidden: true,
          },
          {
            label: "预案类型",
            prop: "planType",
            overHidden: true,
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=er_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "有效日期起",
            prop: "startDate",
            align: "center",
            width: 120,
          },
          {
            label: "有效日期止",
            prop: "endDate",
            align: "center",
            width: 120,
          },
          {
            label: "起草人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "erStatus",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=er_plan_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "创建时间",
            width: 170,
            prop: "createTime",
            align: "center",
          },
        ],
      },
      searchColumns: [
        {
          prop: "planCode",
          span: 3,
          placeholder: "请输入预案编号",
        },
        {
          prop: "planName",
          span: 3,
          placeholder: "请输入预案名称",
        },
        {
          label: "预案类型",
          prop: "planType",
          span: 3,
          type: "select",
          placeholder: "请选择预案类型",
          dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=er_type`,
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          span: 3,
          prop: "erStatus",
          type: "select",
          dataType: "string",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=er_plan_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          display: !this.isIndex, // 从弹框进入则隐藏 true 隐藏 false 不隐藏
          placeholder: "请选择状态",
        },
        {
          prop: "startToEndTime",
          span: !this.isIndex ? 5 : 4,
          type: "daterange",
          format: "yyyy-MM-dd",
          valueFormat: "yyyy-MM-dd",
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间",
        },
      ],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.erplan_add, false),
        viewBtn: this.vaildData(this.permission.erplan_view, false),
        delBtn: this.vaildData(this.permission.erplan_delete, false),
        editBtn: this.vaildData(this.permission.erplan_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.isIndex) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-delete",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
  },
  mounted() {
    // this.getPrjstructureList()
  },
  methods: {
    //项目组织架构
    getPrjstructureList() {
      const parentId = this.userInfo.dept_id;
      getDeptTree("", "", parentId).then((res) => {
        if ((res.data.code = 200)) {
          this.treeData = res.data.data;
          this.$refs.commonTree.setCurrentKey(this.treeData[0].id);
          this.$refs.commonTree.nodeClick(this.treeData[0], {});
        }
      });
    },
    handleNode(node) {
      this.treeNode = node;
      this.onLoad(this.page, {});
    },
    //   新增
    handleAdd() {
      this.$router.push({
        path: `/business/contingency/emergencyPlan/add`,
        query: {
          type: "add",
        },
      });
    },
    // 全局删除
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erplanRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/business/contingency/emergencyPlan/edit`,
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    //查看
    rowView(row) {
      this.$router.push({
        path: `/business/contingency/emergencyPlan/view`,
        query: {
          type: "view",
          id: row.id,
        },
      });
    },
    // 单个删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erplanRemove(row.id);
        })
        .then(() => {
          this.gridHeadSearch({});
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, {});
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.gridHeadLayout.toggleSelection();
    },
    // 页码--第几页
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    // 页码--多少页
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (
        this.$refs.gridHeadLayout.searchForm.startToEndTime &&
        this.$refs.gridHeadLayout.searchForm.startToEndTime.length != 0
      ) {
        this.$refs.gridHeadLayout.searchForm.startDate =
          this.$refs.gridHeadLayout.searchForm.startToEndTime[0];
        this.$refs.gridHeadLayout.searchForm.endDate =
          this.$refs.gridHeadLayout.searchForm.startToEndTime[1];
      } else {
        delete this.$refs.gridHeadLayout.searchForm.startDate;
        delete this.$refs.gridHeadLayout.searchForm.endDate;
      }
      let searchobj = {};
      if (!this.isIndex) {
        this.$refs.gridHeadLayout.searchForm.erStatus = "FINISHED";
        this.$refs.gridHeadLayout.searchForm.unexpired = true; // 查询有效期内的数据
      }
      searchobj = {
        startDate: this.$refs.gridHeadLayout.searchForm.startDate,
        endDate: this.$refs.gridHeadLayout.searchForm.endDate,
        erStatus: this.$refs.gridHeadLayout.searchForm.erStatus,
        planName: this.$refs.gridHeadLayout.searchForm.planName,
        planCode: this.$refs.gridHeadLayout.searchForm.planCode,
        planType: this.$refs.gridHeadLayout.searchForm.planType,
        unexpired: this.$refs.gridHeadLayout.searchForm.unexpired,
      };
      erplanPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, searchobj, { orgId: this.treeNode.id })
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    // 弹窗选择回显
    selectRow(row) {
      this.$emit("selectPlan", row);
    },
  },
};
</script>

  <style>
</style>
