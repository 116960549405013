<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t(`cip.index.atomIndex.title`)"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
      @head-query="headQuery"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
      <IndexCode ref="indexCode" :head-form="dataForm" :type="type" :table-data="dataForm.interfaceLines" @changeIsCache="changeIsCache"></IndexCode>
    </el-scrollbar>
    <el-drawer
      direction="rtl"
      :with-header="false"
      size="40%"
      :visible.sync="drawerShow"
      v-if="drawerShow"
    >
      <head-layout
        :head-btn-options="headBtnOptionsDrawer"
        :head-title="$t(`cip.index.intf.simulateCall`)"
        @simulate-call="simulateCall"
      ></head-layout>
      <el-scrollbar>
        <form-layout
          ref="formLayoutDrawer"
          :column="formColumnDrawer"
          :dataForm.sync="dataFormDrawer"
        ></form-layout>
      </el-scrollbar>
      <div class="avue-dialog__footer">
        <el-button size="small" @click="drawerShow = false">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {selectById, saveOrUpdate, checkInterfaceCodeExits} from "@/api/index/appInterface";
import IndexCode from "@/views/business/index/appIntf/indexCode.vue";
import {appIndexDataApiIntf} from "@/api/index/indexManage";

export default {
  name: "interfaceEdit",
  components: {
    IndexCode,
    FormLayout,
    HeadLayout,
  },
  data() {
    return {
      type: 'view',
      id: '',
      drawerShow: false,
      dataForm: {
        intfUrl: '',
        intfCode: '',
        intfName: '',
        timeDimension: 'M',
        interfaceLines: []
      },
      dataFormDrawer:{
        currentPage: 1,
        pageSize: 50,
        timeSelect:[],
        intfParams: {},
        intfResultRows: JSON.stringify({}),
      },
      intfShowInfo:{
        currentPage: 1,
        pageSize: 50,
        intfCode: '',
        timeStart:'',
        timeEnd:''
      },
    }
  },
  computed: {
    formColumn() {
      return [
        {
          label: this.$t('cip.index.intf.intfCode'),
          labelWidth: 130,
          prop: "intfCode",
          // disabled: !['edit', 'add'].includes(this.type),
          placeholder: this.$t(`cip.index.intf.intfCode`),
          disabled: true,
          rules: [{
            // required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.intf.intfCode'),
            trigger: "blur"
          }],
          span: 8
        },
        {
          label: this.$t('cip.index.intf.intfName'),
          labelWidth: 130,
          prop: "intfName",
          disabled: !['edit', 'add'].includes(this.type),
          placeholder: this.$t(`cip.index.intf.intfName`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.intf.intfName'),
            trigger: "blur"
          }],
          span: 8
        },
        {
          label: this.$t('cip.index.intf.timeDimension'),
          labelWidth: 130,
          prop: "timeDimension",
          type: 'select',
          dataType: 'string',
          disabled: !['edit', 'add'].includes(this.type),
          placeholder: this.$t(`cip.index.intf.timeDimension`),
          span: 8,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.intf.timeDimension'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t(`cip.index.omission.isCache`),
          prop: 'isCache',
          type: 'radio',
          labelWidth: 130,
          disabled: true,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
        },
        {
          label: this.$t('cip.index.intf.intfUrl'),
          labelWidth: 130,
          prop: "intfUrl",
          readonly: true,
          placeholder: this.$t(`cip.index.intf.intfUrl`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.intf.intfUrl'),
            trigger: "blur"
          }],
          span: 24
        }
      ]
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t(`cip.cmn.btn.saveBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: this.$t(`cip.cmn.btn.saveBackBtn`),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
            btnOptType: 'saveBack',
          }
        );
      }
      result.push(
        {
          label: this.$t(`cip.index.intf.simulateCall`),
          emit: "head-query",
          type: "button",
          icon: "",
        }
      );
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );

      return result;
    },
    headBtnOptionsDrawer() {
      let result = [];
      result.push(
        {
          label: this.$t(`cip.index.intf.simulateCall`),
          emit: "simulate-call",
          type: "button",
          icon: "",
          btnOptType: 'primary',
        }
      );

      return result;
    },
    formColumnDrawer(){
      return [
        {
          label: this.$t('cip.index.intf.timeSelect'),
          labelWidth: 130,
          prop: "timeSelect",
          type: 'datetimerange',
          defaultTime: ['00:00:00', '23:59:59'],
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          startPlaceholder: this.$t(`cip.index.intf.timeSelectStart`),
          endPlaceholder: this.$t(`cip.index.intf.timeSelectEnd`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.timeSelect'),
            trigger: "blur"
          }],
          span: 24,
          change: () => {
            setTimeout(() => {
              this.intfShowInfoFun();
            },500);
          }
        },
        {
          label: this.$t('cip.index.intf.currentPage'),
          labelWidth: 130,
          prop: "currentPage",
          type: 'number',
          placeholder: this.$t(`cip.index.intf.currentPage`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.currentPage'),
            trigger: "blur"
          }],
          span: 12,
          click: () => {
            setTimeout(() => {
              this.intfShowInfoFun();
            },500);
          }
        },
        {
          label: this.$t('cip.index.intf.pageSize'),
          labelWidth: 130,
          prop: "pageSize",
          type: 'select',
          dataType: 'string',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicData: [
            {dictKey: "10", dictValue: "10"},
            {dictKey: "20", dictValue: "20"},
            {dictKey: "30", dictValue: "30"},
            {dictKey: "40", dictValue: "40"},
            {dictKey: "50", dictValue: "50"},
            {dictKey: "100", dictValue: "100"},
            {dictKey: "500", dictValue: "500"},
            {dictKey: "1000", dictValue: "1000"}
          ],
          placeholder: this.$t(`cip.index.intf.pageSize`),
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.intf.pageSize'),
            trigger: "blur"
          }],
          span: 12,
          change: () => {
            setTimeout(() => {
              this.intfShowInfoFun();
            },500);
          }
        },
        {
          label: this.$t('cip.index.intf.intfParams'),
          labelWidth: 130,
          prop: "intfParams",
          type: 'textarea',
          minRows: 15,
          maxRows: 20,
          readonly: true,
          placeholder: this.$t(`cip.index.intf.intfParams`),
          span: 24
        },
        {
          label: this.$t('cip.index.intf.intfResultRows'),
          labelWidth: 130,
          prop: "intfResultRows",
          type: 'textarea',
          minRows: 15,
          maxRows: 20,
          readonly: true,
          placeholder: this.$t(`cip.index.intf.intfResultRows`),
          span: 24
        }
      ]
    }
  },
  methods: {
    changeIsCache(isCache = 0) {
      this.dataForm.isCache = isCache
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 模拟调用
    headQuery() {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.$refs.indexCode.getSaveInfo().length === 0) {
            this.$message.warning(this.$t('cip.index.intf.message1'));
            return;
          }
          this.drawerShow = true;
        }
      })
    },
    commonSave(cancel){
      let headerData = this.$refs.formLayout.dataForm;
      headerData.interfaceLines = this.$refs.indexCode.getSaveInfo();
      headerData.intfUrl = `${process.env.VUE_APP_BASE_INDEXINTFURL}` + '/sinoma-index/api/appIndexIntf?intfCode=' + this.dataForm.intfCode;
      saveOrUpdate({
        ...headerData
      })
        .then(res => {
          const {msg, data} = res.data;
          if (data.code == 200) {
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
          } else {
            this.$message.success(msg);
          }
          if (cancel) {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          } else {
            //刷新数据
            Object.assign(this.dataForm, data)
            this.initData();
            this.type = 'edit';
          }
        })
        .finally(() => {
          this.$refs.formLayout.$refs.form.allDisabled = false;
        });
    },
    headSave(cancel) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.dataForm.id) {
            this.commonSave(cancel);
          }else{
            checkInterfaceCodeExits(this.dataForm).then(res => {
              if (res.data.data) {
                this.$refs.formLayout.$refs.form.allDisabled = false
                this.$message.warning(this.$t('cip.index.intf.message2'));
                return;
              }
              this.commonSave(cancel);
            })
          }
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },

    initData() {
      selectById(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        this.id = data.id;
      })
    },
    // 从列表中获取指标编码
    getLineIndexCode(){
      if (this.$refs.indexCode.getSaveInfo().length>0) {
        return this.$refs.indexCode.getSaveInfo().map(e => e.indexCode).join(',');
      }
      return '';
    },
    intfShowInfoFun(){
      this.intfShowInfo.currentPage = this.dataFormDrawer.currentPage;
      this.intfShowInfo.pageSize = this.dataFormDrawer.pageSize;
      this.intfShowInfo.intfCode = this.dataForm.intfCode;
      if(this.dataFormDrawer.timeSelect.length===2){
        this.intfShowInfo.timeStart = this.dataFormDrawer.timeSelect[0];
        this.intfShowInfo.timeEnd = this.dataFormDrawer.timeSelect[1];
      }else{
        this.intfShowInfo.timeStart = '';
        this.intfShowInfo.timeEnd = '';
      }
      this.dataFormDrawer.intfParams = {};
      this.dataFormDrawer.intfParams = JSON.stringify(this.intfShowInfo, null, '\t');
    },
    // 模拟调用确认按钮
    simulateCall() {
      console.log("666666");
      const _this = this;
      this.$refs.formLayoutDrawer.$refs.form.validate(async (valid) => {
        if (valid) {
          _this.$refs.formLayoutDrawer.$refs.form.allDisabled = false;
          let params = {
            intfCode: _this.dataForm.intfCode,
            timeStart: _this.dataFormDrawer.timeSelect[0],
            timeEnd: _this.dataFormDrawer.timeSelect[1]
          };
          appIndexDataApiIntf(
            _this.dataFormDrawer.currentPage,
            _this.dataFormDrawer.pageSize,
            params
          ).then(res => {
            _this.dataFormDrawer.intfResultRows = JSON.stringify(res.data)
            _this.dataFormDrawer.intfResultRows = JSON.stringify(res.data, null, '\t');
          })
        }
      });
    },
  },
  created() {
    let {id, pageType} = this.$route.query;
    this.type = pageType;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData();
    } else {
      // 新增
    }
    this.intfShowInfoFun();
  }
}
</script>

<style scoped>

</style>
