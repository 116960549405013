<template>
  <div style="background-color: #ffffff" class="emergencyPlan">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave"
      @head-submit="headSubmit"
      @head-import="headImport"
      @head-export="headExport"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <avue-form
        :option="formOptions"
        ref="addForm"
        v-model="addForm"
        :upload-preview="handlePreview"
        :upload-after="uploadAfter"
        :upload-error="uploadError"
        :upload-exceed="uploadExceed"
        :upload-before="uploadBefore"
      >
        <template slot="planCode">
          <el-input
            placeholder="请输入预案编号"
            v-model="addForm.planCode"
            :disabled="addForm.isAutomatic || pageDisabled || addForm.id"
          >
            <span slot="append">
              自动生成
              <el-switch
                :disabled="pageDisabled || addForm.id"
                v-model="addForm.isAutomatic"
                active-color="#13ce66"
                @change="handleSwitch"
              >
              </el-switch>
            </span>
          </el-input>
        </template>
      </avue-form>
      <!-- tab标签 -->
      <tabsPane v-show="addForm.id" ref="tabsPane"></tabsPane>
    </div>
  </div>
</template>
  <script>
import HeadLayout from "@/views/components/layout/head-layout";
import tabsPane from "@/views/business/contingency/emergencyPlan/components/tabsPane";
import { erplanAdd, erplanGetDetail } from "@/api/contingency/emergencyPlan";
import { getPageCode } from "@/api/system/serialNumber";
import { getToken } from "@/util/auth";
import { mapGetters } from "vuex";
import {formatTime} from "@/util";
export default {
  components: {
    HeadLayout,
    tabsPane,
  },
  data() {
    return {
      headTitle: "",
      formType: "",
      addForm: {
        isAutomatic: true,
        fileArray: [],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    pageDisabled() {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.addForm.erStatus == "FINISHED") {
        return true;
      } else {
        return false;
      }
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType) && this.addForm.erStatus!="FINISHED") {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交",
          emit: "head-submit",
          type: "button",
          btnOptType: "refer",
        });
        // result.push({
        //   label: "导入模板",
        //   emit: "head-import",
        //   type: "button",
        //   //   btnOptType: "import",
        // });
        // result.push({
        //   label: "导出模板",
        //   emit: "head-export",
        //   type: "button",
        //   //   btnOptType: "export",
        // });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        menuFixed: "right",
        column: [
          {
            label: "预案编号",
            prop: "planCode",
            span: 8,
            formslot: true,
            placeholder: "请输入预案编号",
            rules: [
              {
                required: true,
                message: "请输入预案编号",
                trigger: "blur",
              },
              {
                max: 64,
                message: "预案编号不能超出64字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "预案名称",
            prop: "planName",
            span: 8,
            placeholder: "请输入预案名称",
            disabled: this.pageDisabled,
            rules: [
              {
                required: true,
                message: "请输入预案名称",
                trigger: "blur",
              },
              { max: 120, message: '预案名称不能超过120个字符', trigger: 'blur' }
            ],
          },
          {
            label: "预案类型",
            prop: "planType",
            type: "radio",
            span: 8,
            disabled: this.pageDisabled,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=er_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "string",
            placeholder: "请选择预案类型",
            rules: [
              {
                required: true,
                message: "请选择预案类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "有效日期起",
            prop: "startDate",
            span: 8,
            disabled: this.pageDisabled,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            placeholder: "请选择有效日期起",
            value: formatTime(new Date,"yyyy-MM-dd"),
            // pickerOptions: {
            //   disabledDate(time) {
            //     return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
            //   },
            // },
            rules: [
              {
                required: true,
                message: "请选择有效日期起",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const endDate = this.addForm.endDate;
                  if (endDate && value > endDate) {
                    callback(new Error("起始时间不能晚于结束时间"));
                  } else if (endDate && value == endDate) {
                    callback(new Error("起始时间不能等于结束时间"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "有效日期止",
            prop: "endDate",
            span: 8,
            disabled: this.pageDisabled,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            placeholder: "请选择有效日期止",
            pickerOptions: {
              disabledDate: this.endPickerOptions,
            },
            rules: [
              {
                required: true,
                message: "请选择有效日期止",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  const startDate = this.addForm.startDate;
                  if (startDate && value < startDate) {
                    callback(new Error("结束时间不能早于起始时间"));
                  } else if (startDate && value == startDate) {
                    callback(new Error("结束时间不能等于起始时间"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "备案号",
            prop: "registerCode",
            span: 8,
            disabled: this.pageDisabled,
            placeholder: "请输入备案号",
            rules:[
              { max: 120, message: '备案号不能超过120个字符', trigger: 'blur' }
            ]
          },
          {
            label: "适用范围",
            prop: "remark",
            span: 24,
            disabled: this.pageDisabled,
            type: "textarea",
            placeholder: "请输入适用范围",
            rules:[
              { max: 250, message: '适用范围不能超过250个字符', trigger: 'blur' }
            ]
          },
          {
            label: "处置程序",
            prop: "process",
            span: 24,
            disabled: this.pageDisabled,
            type: "textarea",
            placeholder: "请输入处置程序",
            rules:[
              { max: 250, message: '处置程序不能超过250个字符', trigger: 'blur' }
            ]
          },
          {
            label: "处置措施",
            prop: "treatment",
            span: 24,
            disabled: this.pageDisabled,
            type: "textarea",
            placeholder: "请输入处置措施",
            rules:[
              { max: 250, message: '处置措施不能超过250个字符', trigger: 'blur' }
            ]
          },
          {
            label: "附件上传",
            prop: "fileArray",
            dataType: "object",
            headers: this.headers,
            type: "upload",
            accept: ".zip,.doc,.docx,.pdf",
            limit: 1,
            fileSize: 50000,
            disabled: this.pageDisabled,
            placeholder: "请上传文件",
            propsHttp: {
              name: "originalName",
              url: "link",
              res: "data",
            },
            fileText: "上传文件",
            tip: "支持扩展名：.zip .doc .docx .pdf",
            span: 24,
            action:
              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
          },
        ],
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    init() {
      let type = this.$route.query.type;
      this.formType = type;
      this.headTitle = "应急预案基本信息";
      if (this.formType == "add") {
        this.handleSwitch();
      } else if (this.formType == "edit") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      } else if (this.formType == "view") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      }
    },
    // 基本信息预案编号是否自动生成
    handleSwitch() {
      if (this.addForm.isAutomatic) {
        this.getPageCode();
      } else {
        this.addForm.planCode = "";
      }
    },
    getPageCode() {
      // 获取编码
      getPageCode({ ruleCode: "ER_CODE" }).then((res) => {
        this.$set(this.addForm, "planCode", res.data.data || "");
        this.$forceUpdate();
      });
    },
    // 设置结束时间不能小于起始时间
    endPickerOptions(time) {
      if (this.addForm.startDate) {
        return time.getTime() < Date.parse(this.addForm.startDate);
      } else {
        return null;
      }
    },
    // 点击预览
    handlePreview(file, column, done) {
      if (file.url) {
        let that = this;
        let fileName = file.name;
        fetch(file.url)
          .then((res) => res.blob())
          .then((blob) => {
            let objectUrl = URL.createObjectURL(blob); // 创建 url 对象
            that.aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
          })
          .catch((err) => {
            // console.log("Request Failed", err);
          });
      }
    },
    // 下载
    aTagDownload(url, name) {
      const fileUrl = url; // 文件的URL地址
      const link = document.createElement("a"); // 创建一个链接元素
      link.href = fileUrl; //重点（如测试发现下载文件不存在/找不到，检查路径）
      link.download = name; // 设置下载文件文件名
      link.style.display = "none";
      document.body.appendChild(link); // 将链接元素添加到页面中
      link.click(); // 触发链接的点击事件以触发图像下载
      document.body.removeChild(link); // 在完成下载后，从页面中移除链接元素
    },
    uploadExceed (limit, files, fileList, column) {
      // console.log(limit, files, fileList, column)
      this.$message.error('只能上传一个文件，请先删除当前文件')
    },
    uploadBefore(file, done, loading, column) {
      done()
    },
    uploadAfter(res, done){
      //其余操作
      done()
    },
    uploadError(error, column) {
        this.$message.warning(error)
    },
    // 保存
    headSave() {
      this.saveOrSubmitFor("PREPARE");
    },
    // 保存提交改变状态
    saveOrSubmitFor(erStatus) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          let emergencyOrganization =
            this.$refs.tabsPane.$refs.emergencyOrganization;
          if (emergencyOrganization && emergencyOrganization.imageUrl) {
            this.addForm.orgImgUrl = emergencyOrganization.imageUrl;
          }
          if (this.addForm.fileArray && this.addForm.fileArray.length != 0) {
            this.addForm.files = JSON.stringify(this.addForm.fileArray);
          }
          this.saveLoading = true;
          this.addForm.erStatus = erStatus;
          this.addForm.orgId = this.userInfo.dept_id;
        //   this.$refs.tabsPane.init(
        //   this.formId,
        //   this.pageDisabled,
        //   this.addForm.erStatus,
        //   this.addForm.orgImgUrl,
        //   this.addForm
        // );
          erplanAdd(this.addForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                this.addForm = data;
                this.formId = data.id;
                this.getDetails();
                if (erStatus == "PREPARE") {
                  this.$refs.addForm.disabled = false;
                } else {
                  this.pageDisabled = true
                  this.$refs.addForm.disabled = true;
                }
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.error(msg);
              }
              // this.$refs.addForm.resetFields();
              done();
              this.saveLoading = false;
            })
            .catch((err) => {
              done();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    // 提交判断
    submitValidate() {
      if (!this.addForm.id) {
        this.$message.error("请先填写基本信息并保存");
        return true;
      }
      let emergencyOrganization =
        this.$refs.tabsPane.$refs.emergencyOrganization;
      if (emergencyOrganization && emergencyOrganization.imageUrl) {
        this.addForm.orgImgUrl = emergencyOrganization.imageUrl;
      } else {
        this.$message.error("请上传组织架构图！");
        return true;
      }
      if (
        this.$refs.tabsPane.$refs.emergencyOrganization.tableData &&
        this.$refs.tabsPane.$refs.emergencyOrganization.tableData.length == 0
      ) {
        this.$message.error("请先填写应急组织中人员清单数据再提交");
        return true;
      }
      // if (
      //   this.$refs.tabsPane.$refs.disposalProcedures.tableData &&
      //   this.$refs.tabsPane.$refs.disposalProcedures.tableData.length == 0
      // ) {
      //   this.$message.error("请先填写处置程序数据再提交");
      //   return true;
      // }
      // if (
      //   this.$refs.tabsPane.$refs.disposalMeasures.tableData &&
      //   this.$refs.tabsPane.$refs.disposalMeasures.tableData.length == 0
      // ) {
      //   this.$message.error("请先填写处置措施数据再提交");
      //   return true;
      // }
      if (
        this.$refs.tabsPane.$refs.emergencySupplies.tableData &&
        this.$refs.tabsPane.$refs.emergencySupplies.tableData.length == 0
      ) {
        this.$message.error("请先填写应急物资数据再提交");
        return true;
      }
    },
    // 提交
    headSubmit() {
      if (!this.submitValidate()) {
        this.saveOrSubmitFor("FINISHED");
      }
    },
    headImport() {},
    headExport() {},
    // 取消
    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 获取详情
    getDetails() {
      erplanGetDetail(this.formId).then((res) => {
        this.addForm = res.data.data;
        if (this.addForm.files && this.addForm.files.length != 0) {
          this.addForm.fileArray = JSON.parse(this.addForm.files);
        }
        this.$refs.tabsPane.init(
          this.addForm.id,
          this.pageDisabled,
          this.addForm.erStatus,
          this.addForm.orgImgUrl,
          this.addForm
        );
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.formContentBox {
  padding: 12px 12px 0;
  height: calc(100% - 76px);
  overflow-y: scroll;
}
</style>
