<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions" :head-title="$t(`cip.index.atomIndex.title`)" @head-save="headSave()"
      @head-save-all="headSaveAll()" @head-save-cancel="headSave(true)" @head-cancel="headCancel" @head-query="headQuery"
      @head-start="headStartTask"></head-layout>
    <el-scrollbar>
      <form-layout ref="formLayout" :column="formColumn" :dataForm.sync="dataForm"></form-layout>

      <IndexCode ref="indexCode" v-show="dataForm.confType === 'COMPOSITE_INDEX'" :head-form="dataForm" :type="type" />

      <el-tabs type="border-card" v-model="activeName" v-show="isScopeVisiable">
        <!--        范围-->
        <el-tab-pane :label="$t(`cip.index.omission.appIndexScopes`)" name="scope">
          <scope ref="scope" :scopeTable="dataForm.appIndexScopes" :headForm.sync="dataForm" :type="type"
            :themeId="themeId" :indexId="id"></scope>
        </el-tab-pane>
        <!--        度量-->
        <el-tab-pane :label="$t(`cip.index.omission.appIndexMeasures`)" name="measure">
          <measure ref="measure" :tableData="dataForm.appIndexMeasures" :headForm.sync="dataForm" :type="type"></measure>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
    <dyn-table-dialog ref="dynTableDialog" :headForm.sync="dataFormOld" :type="type"></dyn-table-dialog>
  </div>
</template>
<script>

import sqlFormatter from 'sql-formatter';


const CONTROLLER_NAME = "/index/themeCuboid";

let baseUrl = '/api/sinoma-system/dict/dictionary?code='
let bizUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import dynTableDialog from "@/views/business/index/appIndex/common/dynTableDialog.vue";
import {
  saveOrUpdate,
  saveOrUpdateAllTime,
  selectById,
  changeCacheTaskStatus,
  getIndexLevel
} from "@/api/index/appIndexManage";
import { listAllMetadataSource, selectCuboidList, getDimensionByCuboid } from "@/api/index/indexManage";
import scope from "./common/scope"
import measure from "./common/measure"
import { getMeasureByCuboid } from "@/api/index/appIndexThemeMeasure";
import IndexCode from './common/indexCode'
export default {
  name: "extItemEdit",
  components: {
    FormLayout,
    HeadLayout,
    dynTableDialog,
    scope,
    measure,
    IndexCode,
  },
  data() {
    return {
      changeFlag: false,
      type: 'view',
      activeName: 'scope',
      visible: false,
      isScopeVisiable: false,
      isCompositionVisiable: false,
      isCombinatorialMeasure: false,
      isMeasureVisiable: true,
      themeId: '',
      id: '',
      cuboidId: '',
      dataForm: {
        busType: '1',
        appIndexMeasures: [],
        appIndexScopes: [],
        compoundMeasureList: []
      },
      dataFormOld: {
        busType: '1',
        appIndexMeasures: [],
        appIndexScopes: [],
        compoundMeasureList: []
      },
      nodeData: {},
      headBtnOptions2: [
        {
          label: "新增",
          emit: "head-addzb",
          type: "button",
          icon: "",
        },
        {
          label: "删除",
          emit: "head-delzb",
          type: "button",
          icon: "",
        },
      ],
      prepend: "",
      append: ""

    }
  },
  computed: {
    headBtnOptions() {
      let result = [];

      if (['add', 'edit', 'copy'].includes(this.type)) {
        if (this.dataForm.cacheJobStatus != '1') {
          result.push(
            {
              label: this.$t(`cip.cmn.btn.saveBtn`),
              emit: "head-save",
              type: "button",
              icon: "",
              btnOptType: 'save',
            }
          );
          result.push(
            {
              label: this.$t(`cip.cmn.btn.saveBtn`) + this.$t(`cip.index.atomIndex.fullDimensionalData`),
              emit: "head-save-all",
              type: "button",
              icon: "",
              btnOptType: 'saveAll',
            }
          );
          result.push(
            {
              label: this.$t(`cip.cmn.btn.saveBackBtn`),
              emit: "head-save-cancel",
              type: "button",
              icon: "",
              btnOptType: 'saveBack',
            }
          );
          if (this.dataForm.id > 0 && this.dataForm.isCache == '1') {
            result.push(
              {
                label: this.$t(`cip.cmn.btn.startBtn`),
                emit: "head-start",
                type: "button",
                icon: "",
              }
            );
          }
        } else {
          result.push(
            {
              label: this.$t(`cip.cmn.btn.stopBtn`),
              emit: "head-start",
              type: "button",
              icon: "",
            }
          );
        }
      }
      result.push(
        {
          label: this.$t(`cip.index.atomIndex.queryData`),
          emit: "head-query",
          type: "button",
          icon: "",
        }
      );
      result.push(
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );

      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.index.atomIndex.indexCode'),
          labelWidth: 130,
          prop: "indexCode",
          readonly: !['add', 'copy'].includes(this.type),
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.index.atomIndex.indexCode`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.indexCode'),
            trigger: "blur"
          }],
          span: 8,
          append: ''
        },
        {
          label: this.$t('cip.index.atomIndex.indexName'),
          labelWidth: 130,
          prop: "indexName",
          span: 8,
          readonly: !['edit', 'add', 'copy'].includes(this.type),
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.index.atomIndex.indexName`),
          prepend: '',
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.indexName'),
            trigger: "blur"
          }],
        },
        {
          // label: this.$t('cip.index.atomIndex.indexType'),
          label: this.$t('cip.index.atomIndex.unitCode'),
          labelWidth: 130,
          prop: "unitCode",
          type: 'select',
          dataType: 'string',
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t('cip.index.atomIndex.unitCode'),
          span: 8,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_unit",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.unitCode'),
            trigger: "blur"
          }],
        },
        {
          // label: this.$t('cip.index.atomIndex.indexName'),
          label: this.$t('cip.index.atomIndex.accuracy'),
          labelWidth: 130,
          prop: "accuracy",
          span: 8,
          readonly: !['edit', 'add', 'copy'].includes(this.type),
          maxlength: 1,
          showWordLimit: true,
          // placeholder: this.$t(`cip.index.atomIndex.indexName`),
          placeholder: this.$t('cip.index.atomIndex.accuracy'),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.accuracy'),
            trigger: "blur"
          }],
        },
        {
          // label: this.$t('cip.index.atomIndex.indexName'),
          label: this.$t('cip.index.atomIndex.alias'),
          labelWidth: 130,
          prop: "alias",
          span: 8,
          readonly: !['edit', 'add', 'copy'].includes(this.type),
          maxlength: 200,
          showWordLimit: true,
          // placeholder: this.$t(`cip.index.atomIndex.indexName`),
          placeholder: this.$t('cip.index.atomIndex.alias')
        },
        {
          label: this.$t('cip.index.atomIndex.theme'),
          labelWidth: 130,
          prop: "theme",
          span: 8,
          readonly: true,
          maxlength: 20,
          showWordLimit: true,
          placeholder: this.$t(`cip.index.atomIndex.theme`),
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.theme'),
            trigger: "blur"
          }],
        },
        {
          // label: this.$t('cip.index.atomIndex.indexType'),
          label: this.$t(`cip.index.omission.timeDimension`),
          labelWidth: 130,
          prop: "timeDimension",
          type: 'select',
          dataType: 'string',
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t(`cip.index.atomIndex.indexType`),
          span: 8,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_time_dimension",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.indexType'),
            trigger: "blur"
          }],
        },
        {
          // label: this.$t('cip.index.atomIndex.indexType'),
          label: this.$t(`cip.index.omission.indexLevel`),
          labelWidth: 130,
          prop: "indexLevel",
          // type: 'text',
          dataType: 'string',
          disabled: true,
          // disabled: false,
          placeholder: this.$t(`cip.index.omission.indexLevel`),
          span: 8,
          // props: {
          //   label: "dictValue",
          //   value: "dictKey"
          // },
          // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_level",
          // rules: [{
          //   required: true,
          //   message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.indexType'),
          //   trigger: "blur"
          // }],
        },
        {
          label: this.$t(`cip.index.omission.isCache`),
          prop: 'isCache',
          type: 'radio',
          labelWidth: 130,
          disabled: false,
          // dicData: [
          //   {
          //     label: '是',
          //     value: 1,
          //   },
          //   {
          //     label: '否',
          //     value: 0,
          //   },
          // ],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t(`cip.index.omission.isCache`),
            trigger: "blur"
          }],
        },
        {
          label: '是否同步到看板',
          prop: 'isSyncDashBoard',
          type: 'radio',
          labelWidth: 130,
          disabled: false,
          // dicData: [
          //   {
          //     label: '是',
          //     value: 1,
          //   },
          //   {
          //     label: '否',
          //     value: 0,
          //   },
          // ],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t(`cip.index.omission.isCache`),
            trigger: "blur"
          }],
        },
        {
          label: this.$t(`cip.index.atomIndex.jobCron`),
          labelWidth: 130,
          prop: 'jobCron',
          readonly: true,
          formslot: true,
          display: false,
          disabled: false,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.index.atomIndex.jobCron`),
            trigger: "blur"
          }],
        },
        {
          label: this.$t(`cip.index.atomIndex.indexCaliber`),
          // label: '业务口径',
          prop: "indexCaliber",
          labelWidth: 130,
          type: "textarea",
          overHidden: "15",
          placeholder: this.$t(`cip.index.atomIndex.indexCaliber`),
          span: 24,
          maxlength: 200,
          showWordLimit: true,
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.index.atomIndex.indexCaliber`),
            trigger: "blur"
          }],
        },
        {
          label: 'api',
          prop: "api",
          labelWidth: 130,
          readonly: true,
          overHidden: "15",
          placeholder: 'api',
          span: 24,
          maxlength: 200,
          showWordLimit: true,
          rules: [{
            required: false,
            message: this.$t('cip.cmn.rule.inputWarning') + 'api',
            trigger: "blur"
          }],
        },
        {
          // label: this.$t('cip.index.atomIndex.indexType'),
          label: this.$t('cip.index.atomIndex.simulationType'),
          labelWidth: 130,
          prop: "simulationType",
          type: 'select',
          dataType: 'string',
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t('cip.index.atomIndex.simulationType'),
          span: 8,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_simulation_type",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.simulationType'),
            trigger: "blur"
          }],
        },
        {
          // label: this.$t(`sinoma.md.extItemCate.remark`),
          label: this.$t('cip.index.atomIndex.simulationValue'),
          prop: "simulationValue",
          labelWidth: 130,
          overHidden: "15",
          placeholder: this.$t('cip.index.atomIndex.simulationValue'),
          span: 16,
          disabled:!['edit', 'add', 'copy'].includes(this.type),
          showWordLimit: true,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.simulationValue'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.index.atomIndex.confType'),
          // label: '配置方案',
          labelWidth: 130,
          prop: "confType",
          type: 'select',
          dataType: 'string',
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t('cip.index.atomIndex.confType'),
          span: 8,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_index_conf_type",
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.confType'),
            trigger: "blur"
          }],
          change: (val) => {
            if (!val.value) return
            if (this.changeFlag) {

              const data = { confType: val.value }
              if (val.value == "COMPOSITE_INDEX") {
                data.compositeIndexList = this.$refs.indexCode.getSaveInfo()
              }
              getIndexLevel(data).then(res => {
                this.dataForm.indexLevel = res.data.data
              }).catch(res => {
              })

            } else {
              this.changeFlag = true;
            }


          }

        },
        {
          label: this.$t('cip.index.atomIndex.dataSource'),
          labelWidth: 130,
          prop: "sourceId",
          type: 'select',
          dataType: 'string',
          filterable: true,
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t(`cip.index.atomIndex.dataSource`),
          dicData: '',
          span: 8,
          display: false,
          props: {
            label: 'name',
            value: 'id',
            key: 'id',
          },
          // rules: [{
          //   required: true,
          //   message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dataSource'),
          //   trigger: "blur"
          // }]
        },
        {
          label: this.$t('cip.index.atomIndex.dynSql'),
          prop: "dynSql",
          labelWidth: 130,
          type: "textarea",
          overHidden: "15",
          placeholder: this.$t('cip.index.atomIndex.dynSql'),
          span: 24,
          minRows: 22,
          maxRows: 22,
          maxlength: 5000,
          showWordLimit: true,
          display: false,
          // rules: [{
          //   required: true,
          //   message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dynSql'),
          //   trigger: "blur"
          // }],
        },
        {
          label: this.$t("cip.index.atomIndex.cuboidId"),
          labelWidth: 130,
          prop: "cuboidId",
          type: 'select',
          span: 8,
          filterable: true,
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t(`cip.index.atomIndex.cuboidId`),
          dicData: '',
          display: false,
          props: {
            label: 'cuboidId',
            value: 'id',
            key: 'cuboidId',
          },
          // rules: [{
          //   required: true,
          //   message: this.$t('cip.cmn.rule.inputWarning') + this.$t('sinoma.md.indexCategory.indexCatCode'),
          //   trigger: ["blur", 'change']
          // }],
          change: (data) => {
            let { value } = data;
            if ("" != value && "-1" != value) {
              this.getDimension(value)
              this.getMeasure(value)
            }

          }
        },
        {
          label: this.$t(`cip.index.atomIndex.compositeFormula`),
          // label: '复合指标公式',
          prop: "compositeFormula",
          labelWidth: 130,
          overHidden: "15",
          placeholder: this.$t(`cip.index.atomIndex.compositeFormula`),
          display: false,
          span: 16,
          showWordLimit: true,
          rules: [],
        },
        {
          label: this.$t(`cip.index.atomIndex.influxdbPoint`),
          labelWidth: 130,
          prop: "influxdbPoint",


          filterable: true,
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t(`cip.index.atomIndex.influxdbPoint`),

          span: 8,
          display: false,
        },
        {
          // label: '函数类型',
          label: this.$t(`cip.index.atomIndex.functionType`),
          labelWidth: 130,
          prop: "functionType",
          type: 'select',
          dataType: 'string',
          filterable: true,
          disabled: !['edit', 'add', 'copy'].includes(this.type),
          placeholder: this.$t(`cip.index.atomIndex.functionType`),

          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=function_value",
          span: 8,
          display: false,

          // rules: [{
          //   required: true,
          //   message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dataSource'),
          //   trigger: "blur"
          // }]
        },

      ]
    },
  },
  watch: {
    'dataForm.isCache': {
      handler(val) {
        // 0 != "" 是false
        if ("-1" != val && val != undefined) {
          this.commonColumn('jobCron', (e) => {
            e.display = val
            if (val) {
              e.rules = [{
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.jobCron'),
                trigger: ["blur", 'change']
              }]
            } else {
              e.rules = []
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
    'dataForm.cacheJobStatus': {

      handler(val) {
        if ("" != val && "-1" != val && val != undefined) {
          this.commonColumn('isCache', (e) => {
            e.disabled = val == '1'
          });
          this.commonColumn('jobCron', (e) => {
            e.disabled = val == '1'
          });
        }
      },
      immediate: true,
      deep: true
    },
    'dataForm.confType': {
      handler(val) {

        if ("" != val && "-1" != val && val != undefined) {

          if ("SQL" == val) {
            this.afterConfTypeChange(true, false, false);
          } else if ("FIELD_MAPPING" == val) {
            this.afterConfTypeChange(false, true, false);
          } else if ("COMPOSITE_INDEX" == val) {
            this.afterConfTypeChange(false, false, true);
          } else if ("FUNCTION_CALCULATE" == val) {
            this.afterConfTypeChange(false, false, false, true)

          } else {
            this.afterConfTypeChange(false, false, false)
          }

        } else {
          // this.afterConfTypeChange(false,false,false)
        }
      },
      immediate: true,
      deep: true
    },
    'dataForm.timeDimension': {
      handler(val) {
        if ("" != val && "-1" != val && val != undefined) {

          this.afterTimeChange(val);
        } else {
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    let { id, pageType, node, themeId } = this.$route.query;
    this.type = pageType;
    this.nodeData = node;
    if (['view', 'edit', 'copy'].includes(this.type)) {
      this.dataForm.id = id
      this.themeId = themeId
      this.initData();
    } else {

      // 新增
      this.changeFlag = true;
      this.dataForm.theme = this.nodeData.name;
      this.themeId = this.nodeData.id;
      this.dataForm.themeId = this.nodeData.id;
      this.dataForm.isCache = 0;
    }
    this.initCuboidIdData();
    this.initDataSource();
  },
  methods: {
    afterConfTypeChange(showSql, showField, showComposite, funType = false) {
      this.isScopeVisiable = showField;
      this.commonColumn('sourceId', (e) => {

        if (showSql) {
          e.display = true
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dataSource'),
            trigger: ["blur", 'change']
          }]
        } else {
          e.rules = []
        }
      });
      this.commonColumn('dynSql', (e) => {
        e.display = showSql
        if (showSql) {
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dynSql'),
            trigger: ["blur", 'change']
          }]
        } else {
          e.rules = []
        }
      });
      this.commonColumn('cuboidId', (e) => {
        e.display = showField
        if (showField) {
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.theme.themeCode'),
            trigger: ["blur", 'change']
          }]
        } else {
          e.rules = []
        }
      });
      this.commonColumn('compositeIndex', (e) => {
        e.display = showComposite
        if (showComposite) {
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.index.app.category.indexCode'),
            trigger: "blur"
          }]
        } else {
          e.rules = []
        }
      });
      this.commonColumn('compositeFormula', (e) => {
        e.display = showComposite
        if (showComposite) {
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.compositeFormula'),
            trigger: "blur"
          }]
        } else {
          e.rules = []
        }
      });
      this.commonColumn('influxdbPoint', (e) => {
        e.display = funType
        if (funType) {
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dataSource'),
            trigger: ["blur", 'change']
          }]
        } else {
          e.rules = []
        }
      });
      this.commonColumn('functionType', (e) => {
        e.display = funType
        if (funType) {
          e.rules = [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.index.atomIndex.dataSource'),
            trigger: ["blur", 'change']
          }]
        } else {
          e.rules = []
        }
      });

    },
    afterTimeChange(val) {
      //修改时间维度后，指标编码后缀按照时间维度自动生成或替换，名称前缀自动替换
      switch (val) {
        case 'H':
          this.commonColumn('indexCode', (e) => {

            e.append = '_H'
            this.append = '_H';

          });
          this.commonColumn('indexName', (e) => {
            // e.prepend = '(小时)'
            e.prepend = '('+this.$t('cip.index.atomIndex.hour')+')';
            this.prepend = '('+this.$t('cip.index.atomIndex.hour')+')';

          });
          break;
        case 'D':
          this.commonColumn('indexCode', (e) => {
            e.append = '_D'
            this.append = '_D';

          });
          this.commonColumn('indexName', (e) => {
            e.prepend = '('+this.$t('cip.index.atomIndex.day')+')';
            this.prepend = '('+this.$t('cip.index.atomIndex.day')+')';

          });
          break;
        case 'M':
          this.commonColumn('indexCode', (e) => {
            e.append = '_M'
            this.append = '_M';
          });
          this.commonColumn('indexName', (e) => {
            e.prepend = '('+this.$t('cip.index.atomIndex.month')+')';
            this.prepend = '('+this.$t('cip.index.atomIndex.month')+')';
          });
          break;
        case 'Y':
          this.commonColumn('indexCode', (e) => {
            e.append = '_Y'
            this.append = '_Y';
          });
          this.commonColumn('indexName', (e) => {
            e.prepend = '('+this.$t('cip.index.atomIndex.year')+')';
            this.prepend = '('+this.$t('cip.index.atomIndex.year')+')';

          });
          break;
        default:
          this.append = '';
          this.prepend = '';
          this.commonColumn('indexCode', (e) => {
            e.append = ''

          });
          this.commonColumn('indexName', (e) => {
            e.prepend = ''

          });
          break;
      }

      if (this.dataForm.indexCode.lastIndexOf(this.append) != -1 && this.dataForm.indexCode.substr(this.dataForm.indexCode.length - this.append.length) == this.append) {
        this.dataForm.indexCode = this.dataForm.indexCode.substring(0, this.dataForm.indexCode.lastIndexOf(this.append))
      }
      this.dataForm.indexName = this.dataForm.indexName.replace(this.prepend, '')
    },
    // 对column公共操作
    commonColumn(propName, fun) {
      this.$refs.formLayout.column.forEach(e => {
        if (e.prop === propName) {
          fun(e);
        }
      })
    },
    initData() {
      selectById(this.dataForm.id).then(res => {
        const { data } = res.data;

        this.dataForm = data;
        this.dataFormOld = Object.assign({}, data);
        if(this.type==='view'){
          this.dataForm.appIndexScopes.map(item=>{
            item.disabled=true
          })
        }

        this.dataForm.busType = '1';
        // 对API处理
        this.dataForm.api = process.env.VUE_APP_BASE_INDEXINTFURL + this.dataForm.api;
        if (this.$refs.measure) {
          data.appIndexMeasures.forEach(bean => {
            bean['$cellEdit'] = true
          })
          this.$refs.measure.tableData = data.appIndexMeasures;
        }
        if (this.$refs.scope) {
          this.$refs.scope.scopeTable = data.appIndexScopes;
        }
        this.themeId = data.themeId;
        this.id = data.id;
        //格式化sql
        if (this.dataForm.dynSql && this.dataForm.dynSql != '') {
          this.dataForm.dynSql = sqlFormatter.format(this.dataForm.dynSql);
        }
        //如果是指标复制 清空指标编码、指标名称、指标id
        if (['copy'].includes(this.type)) {
          this.dataForm.indexCode = ''
          this.dataForm.indexName = ''
          this.dataForm.id = ''
          this.dataForm.api = ''
          // 复制时清掉缓存任务id和启动状态
          this.dataForm.jobId = undefined
          this.dataForm.cacheJobStatus = 0
          this.dataForm.jobTime = undefined
        }

      })
    },
    initDataSource() {
      listAllMetadataSource().then(res => {
        const { data } = res.data;
        this.commonColumn('sourceId', (e) => {
          e.dicData = data.map(item => ({
            id: item.id,
            name: item.sourceName
          }))
        });
      })
    },
    initCuboidIdData(params = {}) {
      params.themeId = this.themeId;
      selectCuboidList(params).then(res => {
        const { data } = res.data;
        this.commonColumn('cuboidId', (e) => {
          e.dicData = data.map(item => ({
            id: item.id,
            cuboidId: (item.cubeRemark === "null" || item.cubeRemark === '' || item.cubeRemark.trim().length === 0) ? item.cuboidId : item.cuboidId + "_" + item.cubeRemark
          }));
        });
      })
    },
    /*
    * 根据cuboidId获取维度
    * */
    getDimension(cuboidId) {
      let params = {}
      params.id = cuboidId;
      //如果cuboidId没修改，则不需要重新获取覆盖
      if (cuboidId != this.dataForm.cuboidId) {
        getDimensionByCuboid(params).then(res => {
          const { data } = res.data;

          let scopeTable = data.map(item => ({
            connectedRelation: 'AND',
            dimension: item.fieldName,
            computeSign: "等于",
            value: '',
            indexId: '',
            tableName: item.tableName
          }));
          this.$refs.scope.scopeTable = scopeTable;

        })
      }

    },
    /**
     * 根据coboidId获取度量
     */
    getMeasure(cuboidId) {
      let params = {}
      params.id = cuboidId;
      //如果cuboidId没修改，则不需要重新获取覆盖
      if (cuboidId != this.dataForm.cuboidId) {
        getMeasureByCuboid(params).then(res => {
          const { data } = res.data;

          let measureTable = data.map(item => ({
            expression: item.expression,
            alias: item.alias,//别名写死为value
            description: item.description,
            dataType: item.dataType,
            auxiliaryCalc: item.auxiliaryCalc
          }));
          this.$refs.measure.tableData = measureTable;

        })
      }
    },
    headSave: function (cancel = false, allTime = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
          } catch (e) {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.$message.warning(e.message)
            return;
          }
          let data = {};
          data = this.$refs.formLayout.dataForm;
          if (data.confType === "FUNCTION_CALCULATE" && ['Y', 'M'].indexOf(data.timeDimension) !== -1) {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.$message.warning("函数指标暂不支持[年、月]时间维度!")
            return;
          }
          // 范围
          if (this.$refs.scope) {
            data.appIndexScopes = this.$refs.scope.getSaveInfo();
          }
          // 度量
          if (this.$refs.measure) {
            data.appIndexMeasures = this.$refs.measure.getSaveInfo();
            if (data.appIndexMeasures.length > 1) {
              this.$message.warning('度量只允许有1个')
              return
            }
          }

          // 复合指标
          if (data.confType === 'COMPOSITE_INDEX') {
            data.compositeIndexList = this.$refs.indexCode.getSaveInfo()
            // 必选
            if (!data.compositeIndexList || data.compositeIndexList.length === 0) {
              this.$refs.formLayout.$refs.form.allDisabled = false;
              this.$message.warning('请至少选择一个复合指标')
              return
            }
          }
          data.dynSql = data.dynSql.replace(/\n/g, " ");
          if (allTime) {

            if (data.confType === 'COMPOSITE_INDEX' && this.type == 'add') {
              this.$loading();
              getIndexLevel({
                confType: 'COMPOSITE_INDEX',
                compositeIndexList: data.compositeIndexList
              }).then((date) => {

                data.indexLevel = date.data.data
                saveOrUpdateAllTime({
                  ...data
                })
                  .then(res => {
                    const { msg, data } = res.data;
                    this.dataForm = data
                    this.$message.success(msg)
                    if (cancel) {
                      this.$router.$avueRouter.closeTag();
                      this.$router.back();
                    } else {
                      //刷新数据
                      Object.assign(this.dataForm, data)
                      this.initData();
                      this.type = 'edit';
                    }
                  })
                  .finally(() => {
                    this.$refs.formLayout.$refs.form.allDisabled = false;
                    this.$loading().close();
                  });
              }).catch(res => {

              })
            } else {
              saveOrUpdateAllTime({
                ...data
              })
                .then(res => {
                  const { msg, data } = res.data;
                  this.dataForm = data
                  this.$message.success(msg)
                  if (cancel) {
                    this.$router.$avueRouter.closeTag();
                    this.$router.back();
                  } else {
                    //刷新数据
                    Object.assign(this.dataForm, data)
                    this.initData();
                    this.type = 'edit';
                  }
                })
                .finally(() => {
                  this.$refs.formLayout.$refs.form.allDisabled = false;
                });
            }

          } else {
            if (data.confType === 'COMPOSITE_INDEX' && this.type == 'add') {
              getIndexLevel({
                confType: 'COMPOSITE_INDEX',
                compositeIndexList: data.compositeIndexList
              }).then((date) => {

                data.indexLevel = date.data.data
                saveOrUpdate({
                  ...data
                })
                  .then(res => {
                    const { msg, data } = res.data;
                    this.dataForm = data
                    this.$message.success(msg)
                    if (cancel) {
                      this.$router.$avueRouter.closeTag();
                      this.$router.back();
                    } else {
                      //刷新数据
                      Object.assign(this.dataForm, data)
                      this.initData();
                      this.type = 'edit';
                    }
                  })
                  .finally(() => {
                    this.$refs.formLayout.$refs.form.allDisabled = false;
                  });

              }).catch(res => {

              })


            } else {
              saveOrUpdate({
                ...data
              })
                .then(res => {
                  const { msg, data } = res.data;
                  this.dataForm = data
                  this.$message.success(msg)
                  if (cancel) {
                    this.$router.$avueRouter.closeTag();
                    this.$router.back();
                  } else {
                    //刷新数据
                    Object.assign(this.dataForm, data)
                    this.initData();
                    this.type = 'edit';
                  }
                })
                .finally(() => {
                  this.$refs.formLayout.$refs.form.allDisabled = false;
                });
            }

          }
        }
      })
    },
    headSaveAll: function (cancel = false, allTime = true) {
      this.headSave(cancel, allTime)
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headQuery() {

      this.$refs.dynTableDialog.openDialog(this.dataFormOld);
    },
    headStartTask() {
      let cacheJobStatus = this.dataForm.cacheJobStatus
      let status;
      let text = "";
      if (cacheJobStatus == "1") {
        // 停止
        status = 0;
        text = "停止"
      } else {
        // 启动
        status = 1;
        text = "启动"
      }
      this.$confirm(`是否${text}该指标缓存任务?`, "提示", {
        type: "warning",
      }).then(() => {
        changeCacheTaskStatus(this.dataForm.id, status)
          .then(res => {
            const { msg } = res.data
            this.$message.success(msg)
            this.dataForm.cacheJobStatus = status
          })
      })
    }
  }
}
</script>

<style scoped></style>
