<template>
  <div style="position: relative">
    <el-row :gutter="10">
      <!-- 组织架构图 -->
      <el-col :span="12">
        <head-layout
          head-title="组织架构图"
          :showIcon="false"
          :head-btn-options="headBtnOrangeOptions"
          @head-upload="headUpload"
          @head-quote-org="headQuoteOrg"
          @head-download="headDownload"
        ></head-layout>
        <div class="uploadBox">
          <el-upload
            style="display: none"
            ref="elUpload"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.gif"
            action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
            :show-file-list="false"
            :headers="headers"
            :on-success="uploadSuccess"
            :before-upload="beforeAvatarUpload"
          >
          </el-upload>
          <div v-if="!imageUrl" class="uploadBoxImage">
            <i class="el-icon-picture-outline avatar-uploader-icon"></i>
            <div>
              {{ pageDisabled ? "暂无组织架构图" : "请上传组织架构图" }}
            </div>
          </div>
          <div class="image__preview" v-else>
            <el-image
              fit="contain"
              class="avatarImage"
              :src="imageUrl"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </el-col>
      <!-- 人员清单 -->
      <el-col :span="12">
        <head-layout
          head-title="人员清单"
          :showIcon="false"
          :head-btn-options="headBtnOptions"
          @head-add="handleAdd"
          @head-delete="handleDelete"
        ></head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          @grid-row-detail-click="rowView"
          @gird-handle-select-click="selectionChange"
          :data-total="page.total"
          :page="page"
          @page-current-change="currentChange"
          @page-size-change="sizeChange"
        >
          <template #customBtn="{ row }" v-if="!this.pageDisabled">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowEdit(row)"
              >编辑
            </el-button>
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowDel(row)"
              >移除
            </el-button>
          </template>
        </grid-layout>
      </el-col>
    </el-row>
    <!-- 人员清单新增 -->
    <personnelDialog ref="personnelDialog"></personnelDialog>
    <quoteOrgDialog
      ref="quoteOrgDialog"
      @callback="handleQuoteOrgCallback"
    ></quoteOrgDialog>
  </div>
</template>

<script>
import {
  erplanmemberPage,
  erplanmemberRemove,
  erplanmemberSubmitBatch,
} from "@/api/contingency/emergencyPlan";
import { getPersonList } from "@/api/responsibility/safetyOrg";
import { getToken } from "@/util/auth";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import personnelDialog from "@/views/business/contingency/emergencyPlan/components/personnelDialog";
import quoteOrgDialog from "./quoteOrgDialog";
export default {
  components: {
    GridLayout,
    HeadLayout,
    personnelDialog,
    quoteOrgDialog,
  },
  data() {
    return {
      imageUrl: "",
      srcList: [],
      form: {},
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableData: [],
      planId: "", // 计划id
      pageDisabled: false, // 默认不禁用  当查看页面点击进入或者提交后则禁用
    };
  },
  computed: {
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOrangeOptions() {
      let buttonBtn = [];
      if (!this.pageDisabled) {
        buttonBtn.push({
          label: "上传",
          emit: "head-upload",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "引用",
          emit: "head-quote-org",
          type: "button",
          icon: "",
        });
      }
      buttonBtn.push({
        label: "下载",
        emit: "head-download",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (!this.pageDisabled) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "移除",
          emit: "head-delete",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    tableOption() {
      let obj = {
        height:681
      };
      obj.column = [
        {
          label: "人员名称",
          prop: "userName",
          align: "center",
          overHidden: true,
        },
        {
          label: "部门组织",
          prop: "orgName",
          align: "center",
          overHidden: true,
        },
        {
          label: "岗位职责",
          prop: "postName",
          align: "center",
          overHidden: true,
        },
        {
          label: "联系方式",
          prop: "tele",
          align: "center",
        },
      ];
      if (!this.pageDisabled) {
        obj.selection = true;
        obj.menu = true;
      } else {
        obj.selection = false;
        obj.menu = false;
      }
      return obj;
    },
  },
  mounted() {},
  methods: {
    // 初始化该组件
    init(id, pageDisabled, erStatus, orgImgUrl) {
      this.planId = id;
      this.pageDisabled = pageDisabled;
      this.imageUrl = orgImgUrl;
      this.srcList = [];
      this.srcList.push(this.imageUrl);
      this.searchList();
    },
    headQuoteOrg() {
      this.$refs.quoteOrgDialog.init();
    },
    handleQuoteOrgCallback(row) {
      this.imageUrl = row.imgUrl;
      this.srcList.push(this.imageUrl);
      this.getPersonListMethod(row.id);
    },
    // 获取引用的组织下的人员清单
    async getPersonListMethod(soOrgId) {
      const { data } = await getPersonList({ soOrgId });
      if (data.code == 200) {
        if (data.data.length) {
          let params = data.data.map((item) => {
            return {
              userName: item.userName,
              userId: item.userId,
              tele: item.tel,
              orgName: item.userOrgName,
              orgId: item.userOrgId,
              postName: item.duty,
              planId: this.planId,
            };
          });
          erplanmemberSubmitBatch(params).then((res) => {
            this.onLoad(this.page, { planId: this.planId });
          });
        }
      }
    },
    searchList() {
      this.onLoad(this.page, { planId: this.planId });
    },
    //   上传
    headUpload() {
      this.$refs["elUpload"].$refs["upload-inner"].handleClick();
    },
    // 下载
    async headDownload() {
      if (!this.imageUrl) {
        this.$message.error("暂时没有可下载图片");
        return false;
      }
      // 获取图片对象和画布对象
      const response = await fetch(this.imageUrl);
      const blob = await response.blob();
      // 创建下载链接
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // 释放 Blob URL
      window.URL.revokeObjectURL(url);
    },
    // 上传成功
    uploadSuccess(response, file) {
      this.imageUrl = "";
      this.srcList = [];
      if (response.code == 200) {
        this.imageUrl = response.data.link;
        this.srcList.push(this.imageUrl);
        this.$emit("uploadImgSuccess", this.imageUrl);
      } else {
        this.$message.error("上传失败，请重新上传");
      }
    },
    // 上传前判断格式
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png";

      if (!isJPG) {
        this.$message.warning("上传图片只能是 自定义文件 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    //   新增
    handleAdd() {
      this.$refs.personnelDialog.init("add", { planId: this.planId });
    },
    // 多选
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    // 全局删除
    handleDelete() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erplanmemberRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page, { planId: this.planId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 行编辑
    rowEdit(row) {
      this.$refs.personnelDialog.init("edit", { id: row.id });
    },
    // 单个删除
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return erplanmemberRemove(row.id);
        })
        .then(() => {
          this.onLoad(this.page, { planId: this.planId });
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 页码--第几页
    currentChange(val) {
      this.page.currentPage = val.currentPage;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 页码--多少页
    sizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.onLoad(this.page, { planId: this.planId });
    },
    // 请求列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      erplanmemberPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {})
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
  },
};
</script>

<style scoped>
.uploadBox {
  width: 100%;
  height: 681px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.uploadBoxImage {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 178px;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
  color: #8c939d;
}

.uploadBoxImage .el-upload:hover {
  border-color: #409eff !important;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.image__preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.avatarImage {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
